import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Column from 'bloko/blocks/column';
import { H2Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import RelatedVacancies from 'lux/components/RelatedVacancies';
import resumeAutoHideNotification from 'lux/components/VacancyResponseForm/ResumeAutoHideNotification';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import Suggest from 'lux/pages/ApplicantSuitableVacancies/components/ApplicantSuitableVacanciesSuggest';

const renderPublishedHeading = (text: string, isPublished: boolean): ReactNode => {
    if (!isPublished) {
        return null;
    }

    return (
        <div className="suitablevacancies__published">
            <H2Section lite>
                <span className="suitablevacancies__published-head">{text}</span>
            </H2Section>
            <VSpacing base={4} />
        </div>
    );
};

const TrlKeys = {
    resumePublished: 'suitableVacancies.resume_published',
};

const ApplicantSuitableVacancies: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const resumeHash = useSelector((state) => state.resume?.hash);
    const autoHideTime = useSelector((state) => state.resume?.autoHideTime);
    const published = useSelector((state) => state.router.location.query.published);
    const resumeStatus = useSelector((state) => state.resume?.status);
    const isSuccessfulPublish = published !== '' && resumeStatus !== 'not_finished';

    useEffect(() => {
        if (!published || !resumeHash || !autoHideTime) {
            return;
        }

        addNotification(resumeAutoHideNotification, {
            props: {
                resumeHash,
                autoHideTime,
                isVacancyResponse: false,
            },
        });
    }, [resumeHash, published, autoHideTime, dispatch, addNotification]);

    return (
        <div className="suitablevacancies__body">
            <Column xs="4" s="8" m="8" l="12">
                {renderPublishedHeading(trls[TrlKeys.resumePublished], isSuccessfulPublish)}
                <RelatedVacancies />
            </Column>
            <Column xs="4" s="8" m="4" l="4">
                <Suggest />
            </Column>
        </div>
    );
};

export default translation(ApplicantSuitableVacancies);

import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import Breadcrumbs from 'lux/components/Breadcrumbs';
import Breadcrumb from 'lux/components/Breadcrumbs/Breadcrumb';
import PageLayout from 'lux/components/PageLayout';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import ApplicantSuitableVacancies from 'lux/pages/ApplicantSuitableVacancies/components';

const getBackurl = (resumeHash) => {
    return `/applicant/resumes/view?resume=${resumeHash}`;
};

const ApplicantSuitableVacanciesPage = ({ trls }) => {
    const resumeHash = useSelector((state) => state.router.location.query.resume);
    return (
        <PageLayout title={trls[ApplicantSuitableVacanciesPage.trls.title]}>
            <Column xs="4" s="8" m="12" l="16" container>
                <Column xs="4" s="8" m="12" l="16">
                    <Breadcrumbs>
                        <Breadcrumb to={getBackurl(resumeHash)}>
                            {trls[ApplicantSuitableVacanciesPage.trls.backToResume]}
                        </Breadcrumb>
                    </Breadcrumbs>
                </Column>
                <ApplicantSuitableVacancies />
            </Column>
        </PageLayout>
    );
};

ApplicantSuitableVacanciesPage.propTypes = {
    trls: PropTypes.object,
};

ApplicantSuitableVacanciesPage.trls = {
    title: 'suitableVacancies.title',
    backToResume: 'suitableVacancies.top.backToResume',
};

export default translation(ApplicantSuitableVacanciesPage);

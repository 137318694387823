import { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H4Section } from 'bloko/blocks/header';
import BlokoLink, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';

import { ApplicantServices } from 'lux/components/ApplicantServices/common';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const PER_PAGE = 3;

const BLOCKS = [
    'proftest',
    'photo',
    'keySkills',
    'skills',
    'salary',
    'metro',
    'language',
    'additionalEducation',
    'attestationEducation',
    'certificates',
    'birthday',
    'gender',
    'personalSite',
    'experience',
    'recommendation',
    'portfolio',
];

const getBlockLink = (field, fieldName, resumeHash) => {
    if (fieldName === 'proftest') {
        return field.value?.resultsReceived ? '/applicant/proforientation' : '/article/proforientation_promo';
    }
    return `/applicant/resumes/edit/${field.block}?resume=${resumeHash}#${fieldName}`;
};

const getFields = (
    resume,
    metroCities,
    applicantResumesIdentifiers,
    resumeIdsProfTestAttached,
    applicantPaymentServices
) => {
    return BLOCKS.reduce((acc, name) => {
        const value = resume[name]?.value;
        let show = !value;
        if (
            [
                'photo',
                'recommendation',
                'portfolio',
                'personalSite',
                'experience',
                'additionalEducation',
                'attestationEducation',
                'keySkills',
            ].includes(name)
        ) {
            show = !value.length;
        }
        if (name === 'language') {
            show = value.length < 2;
        }
        if (name === 'salary') {
            show = !value?.amount;
        }
        if (name === 'metro') {
            show = !value && metroCities.includes(`${resume.area.value?.id}`);
        }
        if (name === 'proftest') {
            show =
                !!applicantResumesIdentifiers.some(
                    (identifier) => !resumeIdsProfTestAttached.includes(identifier['@id'])
                ) && applicantPaymentServices.includes(ApplicantServices.CareerGuidance);
        }
        if (show) {
            acc.push(name);
        }
        return acc;
    }, []);
};

const ApplicantSuitableVacanciesSuggest = ({ trls }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const resume = useSelector((state) => state.resume);
    const metroCities = useSelector((state) => state.metroCities);
    const applicantResumesIdentifiers = useSelector((state) => state.applicantResumesIdentifiers);
    const resumeIdsProfTestAttached = useSelector((state) => state.resumeIdsProfTestAttached);
    const applicantPaymentServices = useSelector((state) => state.applicantPaymentServices);
    const [fields] = useState(
        getFields(resume, metroCities, applicantResumesIdentifiers, resumeIdsProfTestAttached, applicantPaymentServices)
    );

    const currentOffset = currentIndex * PER_PAGE;
    const goToNextPage = () => setCurrentIndex((currentIndex + 1) % Math.ceil(fields.length / PER_PAGE));

    return (
        <>
            <H4Section>{`${trls[ApplicantSuitableVacanciesSuggest.trls.title1]} ${
                trls[ApplicantSuitableVacanciesSuggest.trls.title2]
            }`}</H4Section>
            <div className="suitablevacancies__suggest-list">
                {fields.map((name, index) => {
                    if (index < currentOffset || index >= currentOffset + PER_PAGE) {
                        return null;
                    }
                    return (
                        <Link
                            key={name}
                            to={getBlockLink(resume[name], name, resume.hash)}
                            className="suitablevacancies__suggest-item"
                        >
                            <div className="suitablevacancies__suggest-icon-wrap">
                                <span
                                    className={classnames([
                                        'suitablevacancies__suggest-icon',
                                        `suitablevacancies__suggest-icon_${name}`,
                                    ])}
                                />
                            </div>
                            <div
                                className={classnames('suitablevacancies__suggest-text', {
                                    'suitablevacancies__suggest-text_proftest': name === 'proftest',
                                })}
                            >
                                <Text strong>{trls[ApplicantSuitableVacanciesSuggest.trls.fields[name]]}</Text>
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div className="suitablevacancies__suggest-more">
                <Text size={TextSize.Small}>
                    <BlokoLink appearance={LinkAppearance.Pseudo} kind={LinkKind.Secondary} onClick={goToNextPage}>
                        {trls[ApplicantSuitableVacanciesSuggest.trls.more]}
                    </BlokoLink>
                </Text>
            </div>
        </>
    );
};

ApplicantSuitableVacanciesSuggest.trls = {
    title1: 'suitableVacancies.suitable.make_more',
    title2: 'suitableVacancies.suitable.make_more_2',
    more: 'suitableVacancies.suggest.more',
    fields: {
        proftest: 'suitableVacancies.suggest.proftest',
        photo: 'suitableVacancies.suggest.photo',
        keySkills: 'suitableVacancies.suggest.keySkills',
        skills: 'suitableVacancies.suggest.skills',
        salary: 'suitableVacancies.suggest.salary',
        metro: 'suitableVacancies.suggest.metro',
        language: 'suitableVacancies.suggest.language',
        additionalEducation: 'suitableVacancies.suggest.additionalEducation',
        attestationEducation: 'suitableVacancies.suggest.attestationEducation',
        certificates: 'suitableVacancies.suggest.certificate',
        birthday: 'suitableVacancies.suggest.birthday',
        gender: 'suitableVacancies.suggest.gender',
        personalSite: 'suitableVacancies.suggest.personalSite',
        experience: 'suitableVacancies.suggest.experience',
        recommendation: 'suitableVacancies.suggest.recommendation',
        portfolio: 'suitableVacancies.suggest.portfolio',
    },
};

ApplicantSuitableVacanciesSuggest.propTypes = {
    trls: PropTypes.object,
};

export default translation(ApplicantSuitableVacanciesSuggest);

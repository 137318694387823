import { FC } from 'react';

import { ArrowScaleSmallKindRight, IconColor } from 'bloko/blocks/icon';

const BreadcrumbsDivider: FC = () => (
    <div className="breadcrumbs__divider">
        <ArrowScaleSmallKindRight initial={IconColor.Gray30} />
    </div>
);

export default BreadcrumbsDivider;
